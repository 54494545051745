// Libraries
import moment from 'moment';
// Models
import { IPeriods } from '@models/Periods';
// Constants
import { TypesFormatDates } from '@constants/TypesFormatDate';
// Utils
import { range } from './Array';

/**
 * This utilities formats a date with moment.
 * @constructor
 * @param  {string|Date} date - Current date to format.
 * @param  {TypesFormatDates} typeFormat - Type format.
 * @returns string
 */
export const formatMomentDate = (date: string | Date, typeFormat: TypesFormatDates): string => {
    return moment(date).format(typeFormat);
};

/**
 * This utilities format a date with zero time.
 * @constructor
 * @param  {string|Date} date - Current date to format.
 * @returns date
 */
export const setZeroDate = (date: string | Date): Date => moment(date).set({ hour: 0, minute: 0, second: 0 }).toDate();

/**
 * This utilities get years.
 * @returns number[]
 */
export const getYears = (): number[] => range(1800, moment().year() + 100, 1);

/**
 * This constant only return current date in string. Example: '2022-05-05'
 * @param {Date} date - Optional date to get only string
 * @returns {string}
 */
export const onlyDateString = (date: Date = new Date()): string => new Date(date).toISOString().split('T')[0];

/**
 * Helper to get period by monthly year period
 * @param {IPeriods} currentPeriod - Current period selected
 * @param {IPeriods[]} monthly - List of periods by year
 * @return {IPeriods | null}
 */
export const getMonthlyYearPeriod = (currentPeriod: IPeriods, monthly: IPeriods[]): IPeriods | null => {
    const monthly_start_date = currentPeriod.start_date;
    const monthly_end_date = currentPeriod.end_date;
    return monthly.find(month => month.start_date === monthly_start_date && month.end_date === monthly_end_date) || null;
};

/**
 * This constant only return number of days between two dates. Example: 20
 * @param {Date | string} date - current day to format
 * @param {string} dateLimit - limit date to format
 * @returns {mnumber} numberOfDays
 */
export const getDaysFromTwoDates = (date: Date | string, dateLimit: string): number => {
    let numberOfDays = 0;
    const currentDate = new Date();

    if (dateLimit) {
        numberOfDays = moment(dateLimit).diff(moment(date ? date : currentDate), 'days') + 1;
    }

    return numberOfDays;
};

/**
 * This constant only return number of days between two dates. Example: 20
 * @param {Date} date - current day to format
 * @param {string} secondDate - limit date to format
 * @returns {number} numberOfDays
 */
export const differenceBetweenDates = (date: Date, secondDate?: string): { diffWithCurrentDateDays: number } => {
    const dateForCompare = secondDate ? new Date(secondDate) : new Date();
    const parseDate = new Date(date);

    const diffWithCurrentDate = (parseDate.valueOf() - dateForCompare.valueOf()) / 1000;

    const diffWithCurrentDateDays = Math.floor(diffWithCurrentDate / (24 * 60 * 60));

    return {
        diffWithCurrentDateDays,
    };
};

/**
 * Function that format the month
 * @param month The month to be formated
 * @returns Month formated
 */
export const padMonth = (month: number): string => {
    const monthFormated = month < 10 ? '0' + month : month + '';
    return monthFormated;
};
