import { createApi } from '@reduxjs/toolkit/query/react';
import { urls } from '@api/urls';

import { keepDataPerSeconds } from '@redux/constants/queryConfig';
import { baseQuery } from '../baseQuery';
import { ICurrentYearPeriod } from '@models/Utils';
import {
    TGetYearMonthlyPeriodsByDateRangeRequest,
    TGetYearMonthlyPeriodsByDateRangeResponse,
    TGetYearPeriodsModelResponse,
} from './types';

export const yearPeriodsApi = createApi({
    baseQuery,
    reducerPath: 'yearPeriodsApi',
    keepUnusedDataFor: keepDataPerSeconds.yearPeriodApi,
    refetchOnReconnect: true,
    endpoints: build => ({
        getSemesterPeriodsForBonus: build.query<ICurrentYearPeriod[], string>({
            query: (year: string) => {
                return {
                    url: `${urls.utils.yearPeriod.current('bonus', year)}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: ICurrentYearPeriod[]) => response,
        }),

        getYearPeriodsForSeverance: build.query<TGetYearPeriodsModelResponse, string>({
            query: (socialBenefitType: string) => {
                return {
                    url: `${urls.utils.yearPeriod.getYearPeriodsForSeverance(socialBenefitType)}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: TGetYearPeriodsModelResponse) => response,
        }),

        getYearMonthlyPeriodsByDateRange: build.query<
            TGetYearMonthlyPeriodsByDateRangeResponse,
            TGetYearMonthlyPeriodsByDateRangeRequest
        >({
            query: (request: TGetYearMonthlyPeriodsByDateRangeRequest) => {
                return {
                    url: `${urls.utils.yearPeriod.getYearMonthlyPeriodsByDateRange(request.startDate, request.endDate)}`,
                    method: 'GET',
                };
            },
            transformResponse: (response: TGetYearMonthlyPeriodsByDateRangeResponse) => response,
        }),
    }),
});

export const {
    useGetSemesterPeriodsForBonusQuery,
    util,
    useGetYearPeriodsForSeveranceQuery,
    useGetYearMonthlyPeriodsByDateRangeQuery,
} = yearPeriodsApi;
