// Models
import { ICompanyInformation } from '@models/Company';
import {
    BACKGROUNDS_CARD,
    IActiveEmployeesPlans,
    ICreateTransaction,
    IFormikValues,
    IPaymentSavePlan,
    ITypePlan,
    PERIODICITY_PLAN,
} from '@models/Plans';
import { USER } from '@constants/Arrays';

export const refactorRequestData = (data: IActiveEmployeesPlans[]): IActiveEmployeesPlans[] => {
    let index = 0;
    return data.map((item: IActiveEmployeesPlans) => {
        return {
            ...item,
            background: ((): string => {
                const background = BACKGROUNDS_CARD[index].toLocaleLowerCase();
                index = index >= 3 ? 0 : index + 1;
                return background;
            })(),
            employees: `${item.min_employees} a ${item.max_employees}`,
            electronic_payroll: false,
            periodicity: [
                { ...item.monthly, type: PERIODICITY_PLAN.MONTHLY },
                { ...item.quarterly, type: PERIODICITY_PLAN.QUARTERLY },
                {
                    ...item['half-yearly'],
                    type: PERIODICITY_PLAN.BIANNUAL,
                },
                {
                    ...item.annual,
                    type: PERIODICITY_PLAN.ANNUAL,
                },
            ],
        };
    });
};

export const formatCreateTransactionData = ({
    data,
    dataPayment,
    selectedPlan,
    ip,
    promotionId,
}: {
    data: IFormikValues;
    dataPayment: IPaymentSavePlan;
    selectedPlan: ITypePlan;
    ip: string;
    promotionId: string;
}): ICreateTransaction & IPaymentSavePlan => {
    const withElectronicPayrollDescription = selectedPlan?.electronic_payroll
        ? `con nómina electrónica ${selectedPlan.type}`
        : '';
    return {
        company_name: data.company.name,
        value: String(selectedPlan.value),
        plan_id: selectedPlan.id,
        document_number_transaction: `${data.company.document_number}${data.company.dv}`,
        document_type: data.company.document_type,
        promotion_id: promotionId,
        ip,
        min_employees: selectedPlan.min_employees,
        max_employees: selectedPlan.max_employees,
        description: `Compra plan nómina en linea de ${
            selectedPlan.title
        } empleados ${selectedPlan.type.toLocaleLowerCase()} ${withElectronicPayrollDescription}`,
        electronic_payroll: selectedPlan?.electronic_payroll ?? false,
        ...dataPayment,
    };
};

export const formatPaymentSaveData = ({
    formValues,
    selectedPlan,
    information,
}: {
    formValues: IFormikValues;
    selectedPlan: ITypePlan;
    information: ICompanyInformation;
}): IPaymentSavePlan => {
    return {
        firstname: formValues.holder.firstname,
        secondname: formValues.holder.secondname ?? '',
        firstlastname: formValues.holder.firstlastname,
        secondlastname: formValues.holder.secondlastname ?? '',
        document_number: formValues.holder.document_number,
        document_type_id: formValues.holder.document_type,
        email: formValues.holder.email,
        phone: formValues.holder.phone,
        address: formatAddressPayment(formValues),
        country: formValues.holder.country,
        department: formValues.holder.department,
        municipality: formValues.holder.municipality,
        invoice_holder: formValues.holder.invoice_holder || false,
        plan_value: selectedPlan.electronic_payroll ? selectedPlan.value_electronic_payroll_with_check : selectedPlan.value,
        periodicity_id: selectedPlan.periodicity_id,
        company_document_type_id: information.document_type_id ?? '',
        electronic_payroll_only: selectedPlan.electronic_payroll_only ?? false,
        company_document_number: information.document_number ?? '',
        user_role: information.users?.length ? information.users[USER].rol : 'user',
    };
};

export const formatAddressPayment = (formValues: IFormikValues): string => {
    const addressFormated = `${formValues.holder.addressBilling.oneTypeStreet} ${formValues.holder.addressBilling.street} ${
        formValues.holder.addressBilling.oneCardinal ?? ''
    } # ${formValues.holder.addressBilling.number} ${formValues.holder.addressBilling.twoCardinal ?? ''} ${
        formValues.holder.addressBilling.thirdStreet ?? ''
    } ${formValues.holder.addressBilling.twoTypeStreet ?? ''} ${formValues.holder.addressBilling.twoStreet ?? ''}`;
    return addressFormated.trim();
};
