// Constants
import { PeriodicityTypes } from '@constants/Periodicity';
import { Period } from '@models/Periods';
import moment from 'moment';

export const isMonthly = (periodicity: string | undefined): boolean => {
    if (!periodicity) {
        return true;
    }
    const periodicityMonthly = PeriodicityTypes.MONTHLY.toString().toLowerCase();
    return periodicity.toLocaleLowerCase() === periodicityMonthly;
};

export const isWithinPeriod = (date: string, period: Period): boolean => {
    const targetDate = moment(date, 'YYYY-MM-DD');
    const periodStartDate = moment(period.start_date, 'YYYY-MM-DD');
    const periodEndDate = moment(period.end_date, 'YYYY-MM-DD');

    return targetDate.isSameOrAfter(periodStartDate, 'day') && targetDate.isSameOrBefore(periodEndDate, 'day');
};

export const isFortnighttlyPeriod = (periodicity: string): boolean => {
    const periodicityFortnightly = PeriodicityTypes.FORTNIGHTLY.toString().toUpperCase();
    return periodicity.toUpperCase() === periodicityFortnightly;
};
