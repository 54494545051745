// Libraries
import React, { useEffect, useState, KeyboardEvent, ClipboardEvent, useRef } from 'react';
import moment from 'moment';
import DatePicker, { ReactDatePickerProps, registerLocale, ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { FieldHookConfig, useField, useFormikContext } from 'formik';
import es from 'date-fns/locale/es';
registerLocale('es', es);
// Components
import { Icon } from '@components/icon';
import { TooltipInformation } from '@components/tooltip';
// Models
import { ICustomInputProps } from './models/Input';
import { ICustomInput, ICustomInputIcon } from '.';
// Constants
import { InputType, specialFields, validateOnlyIntegers, REGEX_FORMAT } from '@constants/Input';
import { INITIAL_INPUT_VALUE } from '@constants/OtherTexts';
// Utils
import {
    selectSearchOnlyAlphabet,
    validateAlphanumeric,
    validateNumbers,
    validateDateInput,
    validateWord,
    validateWordSpaces,
} from '@utils/Validations';
import { getYears } from '@utils/Dates';
import { capitalizeFirstLetter } from '@utils/Text';
// Hooks
import { useClickAway } from '@hooks/useClickAway';
import { useSelectInput } from './hooks/useSelectInput';
// Styles
import 'react-datepicker/dist/react-datepicker.css';
import './Input.scss';
import './SelectInput.scss';

export const Input: React.FC<React.ComponentProps<'input'> & ICustomInput & FieldHookConfig<string>> = ({
    label,
    showError = true,
    inputClassContainer,
    type,
    classLabel = '',
    tooltip = false,
    textTooltip = '',
    centerInputValues = false,
    maxWidthTooltip,
    valueAlt = '',
    onCustomChange = (): void => {},
    ...props
}) => {
    const [field, meta] = useField(props);

    useEffect(() => {
        onCustomChange(field.value);
    }, [field.value]);

    const validateKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (type === InputType.NUMBER) {
            if (props.onlyIntegers && validateOnlyIntegers.includes(e.key)) {
                e.preventDefault();
                return;
            }
            validateNumbers(e);
        }

        if (type === InputType.ALPHABETICSPACES) validateWordSpaces(e);
        if (type === InputType.ALPHANUMERIC) validateAlphanumeric(e);
        if (specialFields.includes(props.name)) validateWord(e);
    };

    const validateInputNumber = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/[^0-9]/g, '');
    };

    return (
        <div className={`input__container ${inputClassContainer}`} data-testid={field.name}>
            {label && field.name && (
                <div className="label__container">
                    <label htmlFor={field.name} className={`label-input label-margin ${classLabel}`}>
                        {label}&nbsp;
                    </label>
                    {tooltip && (
                        <TooltipInformation
                            textTooltip={textTooltip}
                            iconName="info"
                            typeIcon="png"
                            classNameIcon="label-tooltip-input"
                            maxWidth={maxWidthTooltip}
                        />
                    )}
                </div>
            )}
            <input
                {...field}
                {...props}
                autoComplete="off"
                value={field.value || valueAlt || ''}
                className={`input-styles ${centerInputValues && 'input-styles__center-values'} ${
                    meta.touched && meta.error ? 'border__input--error' : 'border__input'
                } ${props.className} ${props.disabled && 'class__disabled'}`}
                type={type === InputType.PASSWORD ? InputType.PASSWORD : InputType.TEXT}
                onKeyPress={(e): void => validateKeyPress(e)}
                onWheel={type === InputType.NUMBER ? (e): void => e.currentTarget.blur() : (): void => {}}
                onInput={type === InputType.NUMBER ? validateInputNumber : (): void => {}}
            />
            {meta.touched && meta.error && showError && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const TextArea: React.FC<React.ComponentProps<'textarea'> & ICustomInput & FieldHookConfig<string>> = ({
    label,
    inputClassContainer = '',
    type,
    classLabel = '',
    tooltip = false,
    textTooltip = '',
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className={`input__container ${inputClassContainer}`} data-testid={field.name}>
            {label && field.name && (
                <div className="label__container">
                    <label htmlFor={field.name} className={`label-input label-margin ${classLabel}`}>
                        {label}&nbsp;
                    </label>
                    {tooltip && (
                        <TooltipInformation
                            textTooltip={textTooltip}
                            iconName="info"
                            typeIcon="png"
                            classNameIcon="label-tooltip-input"
                        />
                    )}
                </div>
            )}
            <textarea
                {...field}
                {...props}
                autoComplete="off"
                value={field.value || ''}
                className={`input-styles ${meta.touched && meta.error ? 'border__input--error' : 'border__input'} ${
                    props.className
                } ${props.disabled && 'class__disabled'}`}
                onWheel={type === 'number' ? (e): void => e.currentTarget.blur() : (): void => {}}
            />
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const NumberFormatInput: React.FC<NumberFormatProps & ICustomInputProps> = ({
    label,
    name = '',
    symbolIcon = true,
    containerClasses,
    classLabel,
    tooltip = false,
    textTooltip = '',
    currency = false,
    onChangeNumberInput = (): void => {},
    ...props
}) => {
    const [field, meta, helpers] = useField(name);

    return (
        <div className="input__container" data-testid={field.name}>
            {label && field.name && (
                <div className="label__container">
                    <label htmlFor={field.name} className={`label-input label-margin ${classLabel || ''}`}>
                        {label}&nbsp;
                    </label>
                    {tooltip && (
                        <TooltipInformation
                            textTooltip={textTooltip}
                            iconName="info"
                            typeIcon="png"
                            classNameIcon="label-tooltip-input"
                        />
                    )}
                </div>
            )}
            <div
                className={`input-value ${containerClasses || ''} ${
                    meta.touched && meta.error ? 'border__input--error' : 'border__input'
                } ${props.disabled && 'class__disabled'}`}
            >
                {symbolIcon && (
                    <Icon
                        name={!currency ? 'symbolValueGray' : 'symbolCurrency'}
                        type={currency ? 'svg' : 'png'}
                        className="icon--symbol"
                    />
                )}
                <NumberFormat
                    {...props}
                    {...field}
                    decimalScale={0}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    className={props.className}
                    allowLeadingZeros
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        const { value } = e.target;
                        onChangeNumberInput(value);
                        helpers.setValue(value);
                    }}
                />
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const PercentageFormatInput: React.FC<NumberFormatProps & ICustomInputProps> = ({
    label,
    name = '',
    containerClasses,
    classLabel,
    ...props
}) => {
    const [field, meta] = useField(name);

    return (
        <div className="input__container" data-testid={field.name}>
            {label && field.name && (
                <label htmlFor={field.name} className={`label-input label-margin ${classLabel || ''}`}>
                    {label}
                </label>
            )}
            <div
                className={`input-value--percentage ${containerClasses || ''} ${
                    meta.touched && meta.error ? 'border__input--error' : 'border__input'
                } ${props.disabled && 'class__disabled'}`}
            >
                <NumberFormat
                    {...field}
                    {...props}
                    autoComplete="off"
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    className={props.className}
                    allowNegative={false}
                    maxLength={field.value.includes(',') ? field.value.substring(0, field.value.indexOf(',')).length + 3 : 3}
                />
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const DatePickerBase: React.FC<Omit<ReactDatePickerProps, 'onChange'> & ICustomInputProps> = ({
    label,
    name = '',
    withIcon = true,
    placeholderText = 'dd/mm/aaaa',
    customCalendarChange = (): void => {},
    validateMonthChange = (): void => {},
    disableHeaderActions = false,
    ...props
}) => {
    const formik = useFormikContext();
    const [open, setOpen] = useState<boolean>(false);
    const [field, meta] = useField(name);

    const CustomHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        increaseYear,
        decreaseYear,
    }: ReactDatePickerCustomHeaderProps): JSX.Element => {
        return (
            <div className="datepicker-custom-header">
                <div className="datepicker-custom-header__container-input">
                    <div
                        onClick={decreaseMonth}
                        className={disableHeaderActions ? 'datepicker-custom-header__action-disabled' : ''}
                    >
                        <Icon name="arrowLeftGreenLight" type="svg" className="datepicker-custom-header__arrow" />
                    </div>
                    <select
                        value={date.getMonth()}
                        onChange={({ target: { value } }): void => changeMonth(Number(value))}
                        disabled
                    >
                        {moment.months().map(
                            (label, value): JSX.Element => (
                                <option key={value} value={value}>
                                    {capitalizeFirstLetter(label)}
                                </option>
                            )
                        )}
                    </select>
                    <div
                        onClick={increaseMonth}
                        className={disableHeaderActions ? 'datepicker-custom-header__action-disabled' : ''}
                    >
                        <Icon name="arrowRightGreenLight" type="svg" className="datepicker-custom-header__arrow" />
                    </div>
                </div>

                <div className="datepicker-custom-header__container-input">
                    <div
                        onClick={decreaseYear}
                        className={disableHeaderActions ? 'datepicker-custom-header__action-disabled' : ''}
                    >
                        <Icon name="arrowLeftGreenLight" type="svg" className="datepicker-custom-header__arrow" />
                    </div>
                    <select
                        value={Number(moment(date).year())}
                        onChange={({ target: { value } }): void => changeYear(Number(value))}
                        disabled
                    >
                        {getYears().map(
                            (option): JSX.Element => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            )
                        )}
                    </select>
                    <div
                        onClick={increaseYear}
                        className={disableHeaderActions ? 'datepicker-custom-header__action-disabled' : ''}
                    >
                        <Icon name="arrowRightGreenLight" type="svg" className="datepicker-custom-header__arrow" />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="input__container" data-testid={field.name}>
            {label && field.name && <label className="label-input label-margin">{label}</label>}
            <div
                className={`${
                    !props.customInput &&
                    `container__with-icon ${meta.touched && meta.error ? 'border__input--error' : 'border__input'}`
                }  ${props.disabled && 'class__disabled'}`}
            >
                <DatePicker
                    {...props}
                    name={field.name}
                    renderCustomHeader={CustomHeader}
                    dateFormat={props.dateFormat || 'dd/MM/yyyy'}
                    onKeyDown={validateDateInput}
                    excludeDates={props.excludeDates}
                    locale="es"
                    showMonthDropdown={props.showMonthDropdown || true}
                    showYearDropdown={props.showYearDropdown || true}
                    autoComplete="off"
                    dayClassName={(date): string =>
                        `${date.getDay() === 0 ? 'react-datepicker__green-days' : 'react-datepicker__days'}`
                    }
                    dropdownMode="scroll"
                    selected={field.value && new Date(moment(field.value).format('MM/DD/YYYY'))}
                    onSelect={(value: Date): void => {
                        const valueFormated = value.toISOString().split('T')[0];
                        if (field.value == valueFormated) {
                            formik.setFieldValue(field.name, value);
                            customCalendarChange(value);
                            validateMonthChange(value, formik);
                        }
                    }}
                    onChange={(value: Date): void => {
                        formik.setFieldValue(field.name, value);
                        customCalendarChange(value);
                        validateMonthChange(value, formik);
                    }}
                    onChangeRaw={(): void => {
                        formik.setFieldTouched(field.name, true, true);
                        setOpen(false);
                    }}
                    className={props.className}
                    popperClassName="react-datepicker__popper-custom"
                    placeholderText={placeholderText}
                    showPopperArrow={false}
                    open={open}
                    onInputClick={(): void => setOpen(true)}
                    onClickOutside={(): void => setOpen(false)}
                />
                {withIcon && <Icon name="calendarGray" className="icon-size" />}
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const CheckBox: React.FC<React.ComponentProps<'input'> & ICustomInputProps & FieldHookConfig<string>> = ({
    label,
    disabledCheckedProp = false,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className="input__container">
            <div className="checkbox">
                <label className="container">
                    <input
                        {...field}
                        {...props}
                        checked={!disabledCheckedProp ? props.checked : !!field.value}
                        type="checkbox"
                        className={`${meta.touched && meta.error ? 'border__input--error' : ''} ${props.className}`}
                    />
                    <span className="checkmark"></span>
                </label>
                {label && field.name && (
                    <label htmlFor={field.name} className="label-input label-checkbox-margin">
                        {label}
                    </label>
                )}
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const CheckboxGreen: React.FC<React.ComponentProps<'input'> & ICustomInputProps & FieldHookConfig<string>> = ({
    label,
    checkBoxGreenLarge = false,
    textTooltip = '',
    tooltip = false,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className="input__container">
            <div className={`${checkBoxGreenLarge ? 'checkbox-green-large' : 'green--rounded'}`}>
                <label htmlFor={field.name} className={`${props.classLabel ? props.classLabel : 'label-input-white'}`}>
                    {label}
                </label>
                <div className="checkbox_tooltip">
                    {tooltip && <TooltipInformation textTooltip={textTooltip} iconName="info" typeIcon="png" />}
                    <label className="container--rounded">
                        <input
                            {...field}
                            {...props}
                            checked={!!field.value}
                            type="checkbox"
                            className={`${meta.touched && meta.error ? 'border__input--error' : ''} ${props.className}`}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const RoundCheckBox: React.FC<React.ComponentProps<'input'> & ICustomInputProps & FieldHookConfig<string>> = ({
    label,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className="input__container">
            <div className="field--rounded">
                {label && field.name && (
                    <label htmlFor={field.name} className="label-input mr-label">
                        {label}
                    </label>
                )}
                <label className="container--rounded">
                    <input
                        {...field}
                        {...props}
                        checked={!!field.value}
                        type="checkbox"
                        className={`${meta.touched && meta.error ? 'border__input--error' : ''} ${props.className}`}
                    />
                    <span className={`${props.disabled ? 'checkmark-rounded-disabled' : 'checkmark'}`}></span>
                </label>
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const RadioButton: React.FC<React.ComponentProps<'input'> & ICustomInputProps & FieldHookConfig<string>> = ({
    label,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <div className="input__container">
            <div className="field--rounded">
                {label && field.name && (
                    <label htmlFor={field.name} className="label-input mr-label">
                        {label}
                    </label>
                )}
                <label className="container--rounded">
                    <input
                        {...field}
                        {...props}
                        type="radio"
                        className={`${meta.touched && meta.error ? 'border__input--error' : ''} ${props.className}`}
                    />
                    <span className={`${props.disabled ? 'checkmark-rounded-disabled' : 'checkmark-rounded'}`}></span>
                </label>
            </div>
            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const InputIcon: React.FC<React.ComponentProps<'input'> & ICustomInputIcon> = ({
    type,
    value,
    hasIcon,
    iconStyles,
    placeholder,
    customIcon = 'check',
    centerInputValues,
    inputClassContainer = '',
    onChange,
    ...props
}) => {
    const validateKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (type === InputType.ALPHANUMERIC) {
            if (!REGEX_FORMAT.ESPECIAL_CHARACTER.test(e.key)) {
                e.preventDefault();
            }
        }
    };

    const handlePaste = (event: ClipboardEvent<HTMLInputElement>): void => {
        if (!REGEX_FORMAT.ESPECIAL_CHARACTER.test(event.clipboardData.getData(InputType.TEXT))) event.preventDefault();
    };

    return (
        <div className={`input__section ${inputClassContainer} ${hasIcon && 'input__section--icon'}`}>
            <input
                {...props}
                value={value}
                placeholder={placeholder}
                autoComplete="off"
                type={type}
                className={`input-icon ${centerInputValues && 'input-icon__center-values'}  ${props.className} ${
                    props.disabled && 'class__disabled'
                }`}
                onPaste={handlePaste}
                onChange={onChange}
                onKeyPress={(e): void => validateKeyPress(e)}
                onWheel={type === InputType.NUMBER ? (e): void => e.currentTarget.blur() : (): void => {}}
            />
            {hasIcon && <Icon name={customIcon} className={iconStyles} />}
        </div>
    );
};

export const SelectInput: React.FC<React.ComponentProps<'select'> & ICustomInput & FieldHookConfig<string>> = ({
    selectClassContainer = '',
    label = '',
    textTooltip = '',
    classTooltipArea = '',
    options = [],
    customIcon = 'arrowDownGray',
    tooltip = false,
    disabled = false,
    isSearchable = false,
    selectDisabledClass = '',
    hiddenIconSelect = false,
    searchOnlyAlphabet = false,
    onChange = (): void => {},
    menuPlacement = 'auto',
    ...props
}) => {
    const [field, meta] = useField(props);
    const [search, setSearch] = useState<string>('');
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { getCurrentValue, onChangeSelect, getOptions, handleSearchInput } = useSelectInput();
    const currentValue = getCurrentValue(options, field.value);
    const ref = useRef(null);
    const optionsValues = getOptions({ isSearchable, options, search });

    useClickAway(ref, (): void => {
        setShowOptions(false);
    });

    return (
        <div className={`input__container select-input`} data-testid={field.name} ref={ref}>
            {label && field.name && (
                <div className="label__container">
                    <label htmlFor={field.name} className="label-select-input label-margin">
                        {label}&nbsp;
                    </label>
                    {tooltip && (
                        <TooltipInformation
                            textTooltip={textTooltip}
                            classNameTooltip={classTooltipArea}
                            iconName="info"
                            typeIcon="png"
                        />
                    )}
                </div>
            )}
            <div
                className={`select-input-container ${
                    meta.touched && meta.error && 'select-input-container__error'
                } ${selectClassContainer} ${disabled && `select-input-container__disabled ${selectDisabledClass}`}`}
                onClick={(): void => {
                    !disabled && setShowOptions(!showOptions);
                }}
            >
                <div className="select-input-container__value">
                    {isSearchable ? (
                        <input
                            disabled={disabled}
                            onChange={(e): void => {
                                handleSearchInput(e, setSearch);
                                !showOptions && setShowOptions(true);
                            }}
                            placeholder={currentValue.label || INITIAL_INPUT_VALUE}
                            value={search}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                if (searchOnlyAlphabet) {
                                    e.target.value = selectSearchOnlyAlphabet(e.target.value);
                                }
                            }}
                        />
                    ) : (
                        <p>{currentValue.label || INITIAL_INPUT_VALUE}</p>
                    )}
                </div>
                {!hiddenIconSelect && (
                    <div
                        className={`select-input-icon__container ${
                            meta.touched && meta.error && 'select-input-icon__container--error'
                        }`}
                    >
                        <Icon
                            name={customIcon}
                            className={`select-input-icon__container--size ${
                                showOptions && !disabled && 'select-input-icon__container--rotate'
                            }`}
                        />
                    </div>
                )}
                <div
                    className={`select-input__container-options ${`select-input__container-options-placement-${menuPlacement}`} ${
                        showOptions && !disabled && 'select-input__container-options--active'
                    }`}
                >
                    <div
                        className={`select-input__container-options--list   ${
                            !optionsValues.length && 'select-input__container-options--list-empty'
                        } ${`select-input__container-options--list-placement-${menuPlacement}`}`}
                    >
                        <ul className="options">
                            {optionsValues.map((item, index) => (
                                <li
                                    className={`options__item ${currentValue.value === item.value && 'active-option'} ${
                                        item.color && `color-${item.color}`
                                    }`}
                                    key={`${item.value}-${index}`}
                                    onClick={(): void => {
                                        onChangeSelect({ field, option: item, isSearchable, setSearch });
                                        onChange(item);
                                        setShowOptions(false);
                                    }}
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
        </div>
    );
};

export const NumberFormatInputCleanedValues: React.FC<NumberFormatProps & ICustomInputProps> = ({
    label,
    name = '',
    showError = true,
    symbolIcon = true,
    containerClasses,
    classLabel,
    tooltip = false,
    textTooltip = '',
    currency = false,
    onChangeNumberInput = (): void => {},
    ...props
}) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = e.target;
        // Limpiar los puntos y comas
        const cleanedValue = value.replace(/[.,]/g, '');
        onChangeNumberInput(cleanedValue);
        helpers.setValue(cleanedValue);
    };

    return (
        <div className="input__container" data-testid={field.name}>
            {label && field.name && (
                <div className="label__container">
                    <label htmlFor={field.name} className={`label-input label-margin ${classLabel || ''}`}>
                        {label}&nbsp;
                    </label>
                    {tooltip && (
                        <TooltipInformation
                            textTooltip={textTooltip}
                            iconName="info"
                            typeIcon="png"
                            classNameIcon="label-tooltip-input"
                        />
                    )}
                </div>
            )}
            <div
                className={`input-value ${containerClasses || ''} ${
                    meta.touched && meta.error ? 'border__input--error' : 'border__input'
                } ${props.disabled && 'class__disabled'}`}
            >
                {symbolIcon && (
                    <Icon
                        name={!currency ? 'symbolValueGray' : 'symbolCurrency'}
                        type={currency ? 'svg' : 'png'}
                        className="icon--symbol"
                    />
                )}
                <NumberFormat
                    {...props}
                    {...field}
                    decimalScale={0}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    className={props.className}
                    allowLeadingZeros
                    onChange={handleChange}
                />
            </div>
            {meta.touched && meta.error && showError && <div className="text-error">{meta.error}</div>}
        </div>
    );
};
