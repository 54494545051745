/**
 * Constants of the background cards
 */
export enum BACKGROUNDS_CARD {
    'BG-CARD-ONE',
    'BG-CARD-TWO',
    'BG-CARD-THREE',
    'BG-CARD-FOUR',
}

/**
 * Constants of the periodicity plans
 */
export enum PERIODICITY_PLAN {
    MONTHLY = 'Mensual',
    QUARTERLY = 'Trimestral',
    BIANNUAL = 'Semestral',
    ANNUAL = 'Anual',
}

/**
 * This interface is used to define the structure of the plans
 * @constructor
 * @param {string} title - The title of the plan
 * @param {string} discount - The discount of the plan
 * @param {number} value_with_vat - The value with vat of the plan
 * @param {number} value - The value of the plan
 * @param {string} type - The type of the plan
 * @param {boolean} electronic_payroll - Optional the electronic payroll of the plan
 * @param {boolean} electronic_payroll_only - Optional the electronic payroll of the plan
 * @param {number} value_electronic_payroll_with_check - Optional the value electronic payroll with check of the plan
 * @param {number} max_employees - The max employees of the plan
 * @param {string} periodicity_id - The periodicity id of the plan
 * @param {number} value_electronic_payroll - Optional the value electronic payroll of the plan
 * @param {number} values_without_taxes - the value without taxes of the plan
 * @param {number} apply_taxes - boolean to know if apply or not taxes in value plan
 * @param {number} iva - value of iva tax
 * @param {boolean} select - flag to know whether periodicity is select
 * @param {boolean} disable - flag to know whether card periodicity is disable
 */
export interface ITypePlan {
    discounted_value?: number;
    prev_value?: number;
    id: string;
    title?: string;
    discount: string;
    value_with_vat: number;
    value: number;
    type: string;
    electronic_payroll: boolean;
    electronic_payroll_only?: boolean;
    value_electronic_payroll_with_check: number;
    min_employees: number;
    max_employees: number;
    periodicity_id: string;
    value_electronic_payroll: number;
    values_without_taxes: TValuesWithOutTaxes;
    apply_taxes: boolean;
    iva: number;
    select?: boolean;
    disable?: boolean;
}

/**
 * This type define values of plans or periodicity withot taxes
 * @param {number} value - The value of the plan
 * @param {number} value_electronic_payroll - Optional the value electronic payroll of the plan
 * @param {number} value_electronic_payroll_with_check - Optional the value electronic payroll with check of the plan
 */
export type TValuesWithOutTaxes = {
    value: number;
    value_electronic_payroll: number;
    value_electronic_payroll_with_check: number;
};

/**
 * This interface define input for code error
 * @constructor
 * @param {string} key - Key to error
 */
export interface ICodeError {
    [key: string]: string;
}

/**
 * This interface define active employees plans.
 * @constructor
 * @param {string} id - The id of the plan
 * @param {string} employees - The number of employees
 * @param {number} value - The value of the plan
 * @param {ITypePlan} monthly - The monthly plan
 * @param {ITypePlan} quarterly - The quarterly plan
 * @param {ITypePlan} half-yearly - The half-yearly plan
 * @param {ITypePlan} annual - The annual plan
 * @param {string} background - Optional get the background of the plan
 * @param {ITypePlan[]} periodicity - The periodicity of the plan
 * @param {boolean} electronic_payroll - Optional the electronic payroll of the plan
 * @param {number} max_employees - The max employees of the plan
 * @param {number} min_employees - The min employees of the plan
 * @param {number} value_electronic_payroll_with_check - The value electronic payroll with check of the plan
 * @param {number} apply_taxes - boolean to know if apply or not taxes in value plan
 */
export interface IActiveEmployeesPlans {
    id: string;
    employees: string;
    value: number;
    monthly: ITypePlan;
    quarterly: ITypePlan;
    'half-yearly': ITypePlan;
    annual: ITypePlan;
    background?: string;
    periodicity: ITypePlan[];
    electronic_payroll?: boolean;
    max_employees: number;
    min_employees: number;
    value_electronic_payroll_with_check: number;
    apply_taxes: boolean;
}

/**
 * This interface is the str to create a transaction.
 * @constructor
 * @param {string} plan_id - The plan id of the transaction
 * @param {string} promotion_id - The promotion id of the transaction
 * @param {string} description - The description of the transaction
 * @param {string} ip - The ip of the transaction
 * @param {string} document_type - The document type of the transaction
 * @param {string} document_number_transaction - The document number of the transaction
 * @param {string} value - The value of the transaction
 * @param {string} company_name - The company name of the transaction
 * @param {boolean} electronic_payroll - The electronic payroll of the transaction
 */
export interface ICreateTransaction {
    plan_id: string;
    promotion_id: string;
    description: string;
    ip: string;
    document_type: string;
    document_number_transaction: string;
    value: string;
    company_name: string;
    electronic_payroll: boolean;
    min_employees: number;
    max_employees: number;
}

/**
 * This interface is the str to save a payment.
 * @constructor
 * @param {string} document_type_id - The document type id of the payment
 * @param {string} document_number - The document number of the payment
 * @param {string} name - The name of the payment
 * @param {string} phone - The phone of the payment
 * @param {string} email - The email of the payment
 * @param {string} country - The country of the payment
 * @param {string} department - The department of the payment
 * @param {string} municipality - The municipality of the payment
 * @param {string} address - The address of the payment
 * @param {boolean} invoice_holder - The invoice holder of the payment
 * @param {string} plan_id - The plan id of the payment
 * @param {number} plan_value - The plan value of the payment
 * @param {string} periodicity_id - The periodicity id of the payment
 * @param {string} company_document_type_id - The company document type id of the payment
 * @param {string} company_document_number - The company document number of the payment
 * @param {string} user_role - Optional the user role
 * @param {string} company_name - The company name of the payment
 * @param {boolean} electronic_payroll - The electronic payroll of the payment
 */
export interface IPaymentSavePlan {
    document_type_id: string;
    document_number: string;
    firstname: string;
    secondname: string;
    firstlastname: string;
    secondlastname: string;
    phone: string;
    email: string;
    country: string;
    department: string;
    municipality: string;
    address: string;
    invoice_holder: boolean;
    plan_value: number;
    periodicity_id: string;
    company_document_type_id: string;
    company_document_number: string;
    electronic_payroll_only?: boolean;
    user_role?: string;
}

/**
 * This interface is the str to get the current company plan.
 * @constructor
 * @param {string} id - The id of the company plan
 * @param {number} plan_value - The plan value of the company plan
 * @param {string} plan_id - The plan id of the company plan
 * @param {string} periodicity_id - The periodicity id of the company plan
 * @param {number} max_employees - The max employees of the company plan
 * @param {number} min_employees - The min employees of the company plan
 * @param {boolean} is_active - The is active of the company plan
 * @param {number} total_months - The total months of the company plan
 * @param {boolean} electronic_payroll - The electronic payroll of the company plan
 */
export interface ICompanyPlan {
    id: string;
    plan_value: number;
    plan_id: string;
    periodicity_id: string;
    max_employees: number;
    min_employees: number;
    is_active: boolean;
    total_months: number;
    electronic_payroll: boolean;
}

/**
 * Type for main formik values.
 */
type MainFormikValues = {
    document_number: string;
    document_type: string;
    firstname: string;
    secondname?: string;
    firstlastname: string;
    secondlastname?: string;
    phone: string;
    email: string;
    country: string;
    department: string;
    municipality: string;
    direction?: string;
    invoice_holder?: boolean;
    addressBilling: AddressBilling;
};

/**
 * Type for direction billing
 */

type AddressBilling = {
    oneTypeStreet: string;
    street: string;
    oneCardinal?: string;
    number: string;
    twoCardinal?: string;
    twoTypeStreet?: string;
    twoStreet?: string;
    thirdStreet?: string;
};

/**
 * Interface to define to company
 * @constructor
 * @extends MainFormikValues
 * @param {string} name - The name of the company
 * @param {string} dv - The digit verification of the company
 */
export interface ICompanyValues extends Pick<MainFormikValues, 'document_number' | 'document_type'> {
    name: string;
    dv?: string;
}

/**
 * Interface to define the formik values
 * @constructor
 * @param {MainFormikValues} holder - The holder of the formik
 * @param {ICompanyValues} company - The company of the formik
 */
export interface IFormikValues {
    holder: MainFormikValues;
    company: ICompanyValues;
}

/**
 * This interface contain prop of JSX elements
 * @constructor
 * @param {JSX.Element} key - Prop to manage key element
 */
export interface ITextPurchaseProps {
    [key: string]: JSX.Element;
}

/**
 * Interface plans by employer
 * @constructor
 * @param {string} id - Id register
 * @param {string} employer_id - Id employer
 * @param {string} document_type_id - Document type id from employer
 * @param {string} company_document_type_id - Company document type id
 * @param {string} periodicity_id - Periodicity id
 * @param {string} document_number - Document number employee
 * @param {string} company_document_number - Document number company
 * @param {string} user_role - user role company
 * @param {string} name - Employer name
 * @param {string} company_name - Company name
 * @param {string} phone - Phone number employer
 * @param {string} email - Email employer
 * @param {string} country - Country employer
 * @param {string} department - Department employer
 * @param {string} municipality - Municipality employer
 * @param {string} address - Address company
 * @param {boolean} invoice_holder - State invoice holder
 * @param {string} plan_id - Plan id selected
 * @param {number} plan_value - Plan value
 * @param {number} plan_value_taxes - Plan value taxes
 * @param {string} start_date - Start date plan
 * @param {string} finish_date - Finish date plan
 * @param {boolean} is_active - State to know if plan is active
 * @param {string} created_at - Date created register
 * @param {string} updated_at - Date updated register
 * @param {number} min_employees - Min number employees in plan
 * @param {number} max_employees - Max number employees in plan
 * @param {number} total_months - Total months plan
 */
export interface IPlanEmployer {
    electronic_payroll: boolean;
    id: string;
    employer_id: string;
    document_type_id: string;
    company_document_type_id: string;
    periodicity_id: string;
    document_number: string;
    company_document_number: string;
    user_role: string;
    name: string;
    company_name: string;
    phone: string;
    email: string;
    country: string;
    department: string;
    municipality: string;
    address: string;
    invoice_holder: boolean;
    plan_id: string;
    plan_value: number;
    plan_value_taxes: number;
    start_date: string;
    finish_date: string;
    is_active: boolean;
    created_at: string;
    updated_at: string;
    min_employees: number;
    max_employees: number;
    total_months: number;
}

/**
 * Interface to define the data to create a lead.
 * @constructor
 * @param {string} name - The name of the lead
 * @param {string} last_name - The last name of the lead
 * @param {string} city - The city of the lead
 * @param {string} company_name - The company name of the lead
 * @param {string} nit - The nit of the lead
 * @param {number} employees - The employees of the lead
 * @param {string} phone - The phone of the lead
 * @param {string} email - The email of the lead
 * @param {boolean} terms - Optional the terms of the lead
 */
export interface ICreateLead {
    name: string;
    last_name: string;
    city: string;
    company_name: string;
    nit: string;
    employees: number;
    phone: string;
    email: string;
    terms?: boolean;
}

/**
 * Interface to code.
 * @constructor
 * @param {string} code - Prop to code of the coupon
 * @param {string} plan_id - Prop to plan id
 * @param {string} periodicity_id - Prop to periodicity id
 */
export interface IRedeemCode {
    code: string;
    plan_id: string;
    periodicity_id: string;
    customer_discount: boolean;
}

/**
 * Interface to coupon.
 * @constructor
 * @param {string} code - Prop to code of the coupon
 * @param {string} plan_id - Prop to plan id
 * @param {boolean} validateStep - Prop to validate step
 * @param {string} periodicity_id - Prop to periodicity id
 * @param {(state: boolean) => void} handleValidateStep - Prop to handle step
 * @param {() => void} handleOpenModalCoupon - Prop to handle modal coupon
 * @param {() => void} handleOpenConfirmPlan - Prop to handle modal plan
 */
export interface IRedeemCodeState {
    code: string;
    plan_id: string;
    setValidateStep: boolean;
    isCustomerDiscount: boolean;
    periodicity_id: string;
    setHandleValidateStep: (state: boolean) => void;
    handleOpenModalCoupon: () => void;
    handleOpenConfirmPlan: () => void;
}
